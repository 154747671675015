<template>
  <Bar :data="chartData" :options="chartOptions"></Bar>
</template>

<script>
import {Bar} from 'vue-chartjs'
import {Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: "BarChart",
  components: {Bar},
  props: {
    data: {
      type: Object,
    }
  },
  data: () => ({
    chartOptions: {
      scales: {
        y: {
        },
      },
    },
  }),
  computed: {
    chartData() {
      let mapper = {}
      const colors = [
        '#d21f3d',
        '#d21f3d',
        '#f9812a',
        '#f9812a',
        '#ffe22c',
        '#ffe22c',
        '#06b25f',
        '#06b25f',
        '#0078d7',
        '#0078d7'
      ];

      Array.from({length: 10}).forEach((_, i) => {
        if (!mapper[i + 1]) {
          mapper[i] = 0
        }
      })

      this.data.forEach((v) => {
        if (!mapper[v.rating - 1]) {
          mapper[v.rating - 1] = 1;
        } else {
          mapper[v.rating - 1] += 1;
        }
      })

      return {
        labels: Array.from({length: 10}, (_, i) => (i + 1).toString()),
        datasets: [
          {
            label: 'Rating: how you feel?',
            backgroundColor: colors.slice(0, 10),
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
            data: Object.values(mapper),
          },
        ],
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>