import {defineStore} from "pinia";

export const useStore = defineStore('user', {
    state: () => ({
        data: {}
    }),
    actions: {
        setUserData(data) {
            this.data = data
        }
    }
})