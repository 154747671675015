import {Api} from "@/utils/api";

export class SchoolApi extends Api {
    constructor() {
        super();

    }

    async findMy() {
        return await this.getStorage("/school/my")
    }

    async findAllSchools() {
        return await this.getStorage("/school")
    }

    async findOne(id) {
        return await this.getStorage("/school/" + id)
    }

    async create(data) {
        return await this.postStorage("/school/", data)
    }

    async update(id, data) {
        return await this.patchStorage("/school/" + id, data)
    }

    async remove(id) {
        return await this.deleteStorage("/school/" + id)
    }

    async changeSchool(id, schools) {
        return await this.patchStorage("/auth/school/" + id, {school_id: schools})
    }

    async getMySurveys(disableSchools = false) {
        return await this.getStorage("/survey/my", {school: disableSchools ? '0' : null})
    }

    async getUniqueLinkResults(school_id) {
        return await this.getStorage("/survey-answer/unique", {school_id})
    }
}
