<template>
  <form class="flex flex-col items-start  w-fit" @submit.prevent="$emit('submit', form)">
    <img src="/img/png/Logo.png" class="h-[60px] block mx-auto" alt="Logo">
    <h1 class=" font-bold text-primary text-xl text-center">Login</h1>
    <label for="login">Login</label>
    <el-input placeholder="enter login..." id="login" v-model="form.login"/>
    <label for="password">Password</label>
    <el-input placeholder="enter password..." id="password" type="password" v-model="form.password"/>
    <el-button @click="$emit('submit', form)" type="primary" class="mt-2">Submit</el-button>
  </form>
</template>

<script>
export default {
  data: () => ({
    form: {

    }
  }),
  methods: {

  }
}
</script>

<style lang="scss" scoped>

</style>