import DynamicStorage from 'dynamic-storage'

export const storage = DynamicStorage.init()

export function getMonthName(monthNumber) {
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    return monthNames[monthNumber];
}

export const createCopy = (data) => {
    return JSON.parse(JSON.stringify(data))
}