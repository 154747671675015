import {useStore} from "@/store";
import {mapStores} from 'pinia'

export const UserStatusMixin = {
    data: () => ({
        isSignedIn: false,
        isLoading: true,
        mySurveys: [],
        students: [],
    }),
    async mounted() {

    },
    methods: {
        async getMyStudents() {
            this.students = await this.$root.studentApi.getStudents()
        },
        async getMySurveys() {
            this.mySurveys = await this.$root.schoolApi.getMySurveys()
        },
        async isAdmin() {
            return await this.$root.api.isAdmin()
        },
        async checkStatus() {
            if (!localStorage.getItem("access_token")) {
                this.isSignedIn = false;
                this.isLoading = false;
                return
            }

            const res = await this.$root.api.status()

            if (res.error) {
                this.$router.push("/")
                this.$message.error(res.message)
                return;
            }

            this.userStore.setUserData(res)

            this.isLoading = false;
            this.isSignedIn = true
        },
        async submit(data) {
            const res = await this.$root.api.login(data)

            if (res.error) {
                this.$message.error(res.message)
                return
            }

            this.isSignedIn = true
            // location.reload()
        },
    },
    computed: {
        ...mapStores(useStore)
    }
}