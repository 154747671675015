<template>
  <div class="flex gap-3 mt-4 bg-gray-50 p-4 rounded-shadow rounded bg-opacity-30 border-opacity-10">
    <div class="flex flex-col">
      <div class="bg-t-gray badge"></div>
      <span>No</span>
    </div>
    <div class="flex flex-col">
      <div class="bg-t-light-blue badge"></div>
      <span>Sometimes</span>
    </div>
    <div class="flex flex-col">
      <div class="bg-t-blue badge"></div>
      <span>Not often</span>
    </div>
    <div class="flex flex-col">
      <div class="bg-t-dark-blue badge"></div>
      <span>Usually</span>
    </div>
    <div class="flex flex-col">
      <div class="bg-t-darkest-blue badge"></div>
      <span>Always</span>
    </div>
  </div>
</template>

<script>
export default {

  data: () => ({}),
  methods: {},
  watch: {},
  computed: {},
}
</script>

<style lang="scss" scoped>
.badge {
  height: 30px;
  width: 50px;
  border-radius: 10px;
}
</style>