<template>
  <div class="flex flex-col items-start">
    <h3 class="text-primary font-bold text-xl mb-2">{{q}}</h3>
    <div class="rating flex items-center">
      <span :style="{left: firstData * 140 - 70 + 17  + 'px'}" class="dot relative h-[34px] -mt-[60px] w-[34px] bg-gray-300 border-4 border-gray-700 rounded-[50%]"></span>
      <div class="section flex flex-col items-center">
        <div class="rating__color rounded-l-[20px] bg-[#d21f3d]"></div>
        <span class="h-[30px] w-[2px] bg-gray-600"></span>
        1
        <span>worst</span>
      </div>
      <div class="section flex flex-col items-center">
        <div class="rating__color bg-[#f9812a]"></div>
        <span class="h-[30px] w-[2px] bg-gray-600"></span>
        2
        <span>bad</span>
      </div>
      <div class="section flex flex-col items-center">
        <div class="rating__color bg-[#ffe22c]"></div>
        <span class="h-[30px] w-[2px] bg-gray-600"></span>
        3
        <span>moderate</span>
      </div>
      <div class="section flex flex-col items-center">
        <div class="rating__color  bg-[#06b25f]"></div>
        <span class="h-[30px] w-[2px] bg-gray-600"></span>
        4
        <span>good</span>
      </div>
      <div class="section flex flex-col items-center">
        <div class="rating__color bg-[#0078d7] rounded-r-[20px]"></div>
        <span class="h-[30px] w-[2px] bg-gray-600"></span>
        5
        <span>best</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ScaleSlider",
  components: {},
  props: {
    i: {
      type: Number
    },
    q: {
      type: String
    },
    data: {
      type: Object,
    }
  },
  data: () => ({

  }),
  computed: {
    firstData() {
      if(typeof this.data === "number") {
        return this.data
      }
      return this.data[this.i]?.reduce((acc, next) => acc += next.rating, 0 ) / this.data[this.i]?.length
    }
  }
}
</script>

<style lang="scss" scoped>
.rating {
  div {
    width: 140px;
    height: 84px;
  }
}
</style>