<template>
  <footer class="container my-16 font-medium">
    <div v-if="showButtons" class="flex flex-col gap-4 text-white text-[1.5vw] w-full items-center">
      <button @click="$router.push('/sign-up')" class="rounded bg-dark w-[200px] py-2">
        Sign Up
      </button>
<!--      <button class="bg-[#50cbe8] rounded w-[200px] py-2">-->
<!--        Pricing-->
<!--      </button>-->
    </div>

    <div v-if="showLinks" class="w-full flex justify-center mt-16 gap-8 text-[30px]">
      <a href="https://www.facebook.com/Truwell-107847857612474" class="flex items-center justify-center bg-primary rounded-[50%] text-white aspect-square w-[50px]">
        <i class="fa-brands fa-facebook-f"></i>
      </a>
      <a href="https://www.youtube.com/channel/UC90IxPD6BZyW3LUaeUbJlcw" class="flex items-center justify-center bg-primary rounded-[50%] text-white aspect-square w-[50px]">
        <i class="fa-brands fa-youtube"></i>
      </a>
      <a href="https://www.linkedin.com/company/truwell/" class="flex items-center justify-center bg-primary rounded-[50%] text-white aspect-square w-[50px]">
        <i class="fa-brands fa-linkedin-in"></i>
      </a>
      <a href="https://twitter.com/Truwell1" class="flex items-center justify-center bg-primary rounded-[50%] text-white aspect-square w-[50px]">
        <i class="fa-brands fa-twitter"></i>
      </a>
      <a href="https://www.instagram.com/truwell_ai/" class="flex items-center justify-center bg-primary rounded-[50%] text-white aspect-square w-[50px]">
        <i class="fa-brands fa-instagram"></i>
      </a>
    </div>

    <div class="w-full mt-16 flex gap-1 text-center justify-center font-bold text-dark text-[1vw]">
      <a href="/privacy">
        Privacy policy
      </a>
      <span>&</span>
      <a href="/terms-and-conditions">
        Terms and conditions
      </a>
    </div>
  </footer>
</template>

<script>
export default {
  name: "MyFooter",
  props: {
    showButtons: {
      type: Boolean,
      default: true
    },
    showLinks: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({}),
  methods: {},
  watch: {},
  computed: {},
}
</script>

<style lang="scss" scoped>

</style>