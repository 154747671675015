<template>
  <div
      :style="{fontSize: 12 * (size / 80) + 'px'}"
      class="flex flex-col gap-1 font-bold text-[12px] items-center justify-center">
    <div class="flex gap-1">
      <div
          :class="classes[data[i]]"
          :style="{width: size + 'px',height: size + 'px',}"
          class="flex items-center justify-center text-white"
          v-for="(_, i) in Array.from({length: 4})" :key="i">
        {{hideText ?'' : names[i]}}
      </div>
    </div>
    <div class="flex flex-col gap-1">
      <div
          :class="classes[data[i + 4]]"
          :style="{width: size + 'px',height: size + 'px',}"
          class="flex items-center justify-center text-white"
          v-for="(_, i) in Array.from({length: 4})" :key="i">
        {{hideText ? '' : names[i + 4]}}
      </div>
    </div>

    <TChartBadge v-if="!hideBadge"/>
  </div>
</template>

<script>
import TChartBadge from "@/components/Charts/TChartBadge.vue";

export default {
  name: "TChart",
  components: {TChartBadge},
  props: {
    hideBadge: {
      type: Boolean,
      default: false
    },
    hideText: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array
    },
    size: {
      type: Number,
      default: 80
    }
  },
  data: () => ({
    classes: {
      1: "bg-t-gray",
      2: "bg-t-light-blue",
      3: "bg-t-blue",
      4: "bg-t-dark-blue",
      5: "bg-t-darkest-blue",
    },
    names: [
      "HOPEFUL",
      "HAPPY",
      "COPING",
      "LEARNING",
      "BELONGING",
      "SAFE",
      "PHYSICAL HEALTHY",
      "MENTALLY HEALTHY"
    ]
  }),
  methods: {},
  watch: {},
  computed: {},
}
</script>

<style lang="scss" scoped>
</style>