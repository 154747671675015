<template>
  <div v-if="!isLoading">
    <div v-if="isSignedIn">
      <div class="container py-10">
        <h1 class="text-3xl mb-2 font-bold text-primary" v-if="schoolName">{{ schoolName }}</h1>
<!--        <div class="flex gap-2 items-center">-->
<!--          <a class="underline" v-for="s in mySurveys" :key="s.gamePin"-->
<!--             :href="`https://truwell.com/analytics/${s.gameName.replaceAll(' ', '_').toLowerCase()}?pin=${s.gamePin}`">-->
<!--            {{ s.schoolName }} | {{ s.gamePin }}-->
<!--          </a>-->
<!--        </div>-->
        <div v-if="schools?.length > 0">
          You are also connected to these schools: <span class="text-primary">{{ schools?.join(", ") }}</span>
        </div>
        <div class="flex gap-2 w-full justify-center">
          <button @click="category = c" class="border shadow p-3 text-lg rounded transition"
                  :class="category === c ? 'bg-primary text-white hover:bg-sky-600' : 'text-primary hover:bg-primary hover:text-white'"
                  v-for="c in categories" :key="c">
            {{ c }} | {{ getCount(allData[c], 'sessionId') }}
          </button>
        </div>

        <div class="w-full" v-if="data">
          <div class="w-full flex flex-col items-center" v-if="surveyName?.toLowerCase() === 'student wellbeing checkin'">
            <div v-if="surveyInfo.type !== 'email-survey'" class="grid grid-cols-3 gap-2 items-start w-full">
              <div class="flex flex-col items-center" v-for="user in students" :key="user.id">
                <span class="text-primary font-bold text-xl">{{user.firstName + '' + user.lastName}}</span>

                <div class="flex gap-4" v-if="resultById[user.id]">
                    <TChart
                        hide-badge
                        :size="40"
                        :data="[resultById[user.id][4]?.rating,resultById[user.id][5]?.rating,resultById[user.id][6]?.rating,resultById[user.id][7]?.rating, resultById[user.id][2]?.rating, resultById[user.id][1]?.rating, resultById[user.id][0]?.rating, resultById[user.id][3]?.rating]"/>
                </div>
                <div class="text-primary" v-else>
                  No data
                </div>
              </div>
            </div>
            <div v-else class="grid grid-cols-3 gap-2 items-start w-full">
              <div class="flex flex-col items-center" v-for="(key) in Object.keys(resultByEmail)" :key="key">
                <span class="text-primary font-bold text-xl">{{key}}</span>

                <div class="flex gap-4" v-if="resultByEmail[key]">
                  <TChart
                      hide-badge
                      :size="40"
                      :data="[resultByEmail[key][4]?.rating,resultByEmail[key][5]?.rating,resultByEmail[key][6]?.rating,resultByEmail[key][7]?.rating, resultByEmail[key][2]?.rating, resultByEmail[key][1]?.rating, resultByEmail[key][0]?.rating, resultByEmail[key][3]?.rating]"/>
                </div>
                <div class="text-primary" v-else>
                  No data
                </div>
              </div>
            </div>

            <TChartBadge/>
          </div>
          <div v-else>
            <BarChart :data="barChartData"/>
            <div class="flex flex-col gap-4">
              <ScaleSlider q="Staff levels of appreciation and recognition" :i="0" :data="appreciation"/>
              <ScaleSlider q="Staff levels of feeling of belonging to my school community" :i="0"
                           :data="belongingToCommunity"/>
              <ScaleSlider q="Workload" :i="0" :data="workLoad"/>
            </div>

            <div class="flex flex-col gap-2">
              <CommentAnalytic :pin="pin" :data="commentData"/>
            </div>

            <div class="flex flex-col gap-4 mt-8">
              <div class="flex justify-between text-primary font-semibold pl-[50%] w-full">
                <span>Average score.</span>
                <span>Score distribution</span>
              </div>
              <LongTerm q="Staff levels of appreciation and recognition" :i="0" :data="sliderChartData"/>
              <LongTerm q="Staff levels of feeling of belonging to my school community" :i="1" :data="sliderChartData"/>
              <LongTerm q="Workload" :i="2" :data="sliderChartData"/>
            </div>
          </div>
        </div>
        <div v-else>
          <h1 class="text-primary">No data. Please wait</h1>
        </div>
      </div>
    </div>
    <div class="container flex items-center h-screen justify-center" v-else>
      <SigninForm @submit="(d) => submit(d, true)"/>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import BarChart from "@/components/Charts/BarChart.vue";
import ScaleSlider from "@/components/Charts/ScaleSlider.vue";
import LongTerm from "@/components/Charts/LongTerm.vue";
import CommentAnalytic from "@/components/Charts/CommentAnalytic.vue";
import {UserStatusMixin} from "@/mixins/userStatusMixin";
import SigninForm from "@/components/Shared/signin-form.vue";
import TChart from "@/components/Charts/TChart.vue";
import TChartBadge from "@/components/Charts/TChartBadge.vue";

export default {
  name: "AnalyticsView",
  components: {TChartBadge, TChart, SigninForm, CommentAnalytic, LongTerm, ScaleSlider, BarChart},
  async mounted() {
    await this.checkStatus()

    if (this.isSignedIn) {
      const res = await this.$root.api.canAccessSurvey(this.pin)

      if (!res) {
        this.$router.push("/")
      }

      this.schools = res?.schools?.map(s => s.trim())

      await this.getMySurveys()
      await this.getMyStudents()
    }
    const {answers, school, gameName, ...surveyInfo} = await this.$root.api.getInfo(this.pin)
    const data = _.groupBy(answers, "category")
    this.categories = Object.keys(data)
    this.category = this.categories[0]
    this.allData = data
    this.schoolName = school?.name
    this.surveyName = gameName
    this.surveyInfo = surveyInfo
  },
  data: () => ({
    schoolName: "",
    surveyName: "",
    surveyInfo: "",
    allData: null,
    sessionIdValue: '',
    category: null,
    categories: [],
    schools: []
  }),
  mixins: [
    UserStatusMixin
  ],
  methods: {
    getCount(arr, key) {
      return Object.keys(_.groupBy(arr, key)).length
    }
  },
  computed: {
    surveyData() {
      return this.category !== null ? this.allData[this.category] : null
    },
    name() {
      return this.$route.params.id
    },
    pin() {
      return this.$route.query.pin
    },
    data() {
      return this.surveyData
    },
    barChartData() {
      return [...this.chartData[0]]
    },
    sliderChartData() {
      return [this.chartData[1], this.chartData[2], this.chartData[3]]
    },
    workLoad() {
      return [this.surveyData.filter((v) => v.optionId === 3 && v.questionId === 2)]
    },
    belongingToCommunity() {
      return [this.surveyData.filter((v) => v.optionId === 0 && v.questionId === 2)]
    },
    appreciation() {
      return [this.surveyData.filter((v) => v.optionId === 2 && v.questionId === 1)]
    },
    commentData() {
      const data5 = this.chartData[5] ? this.chartData[5] : []
      const data6 = this.chartData[6] ? this.chartData[6] : []

      return [...data5, ...data6]
    },
    chartData() {
      return _.groupBy(this.surveyData, 'questionId')
    },
    resultById() {
      let data = _.groupBy(this.surveyData, 'sessionId')
      return data
    },
    resultByEmail() {
      return _.groupBy(this.surveyData, 'email')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>