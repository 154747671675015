<template>
  <nav class="w-full">
    <div class="container flex justify-between py-2">
      <router-link to="/"><img src="/img/png/Logo.png" class="w-[8vw] lg:w-[5vw]" alt="Logo">
      </router-link>

      <ul class="text-white text-[2.5vw] lg:text-[1.5vw] font-bold uppercase flex gap-5">
        <li>
          <a href="/studentwellbeingcheck-in">
            Check in
          </a>
        </li>
        <li>
          <a href="/studentwellbeing">
            Student
          </a>
        </li>
        <li>
          <a href="/students-panel">
            Login - Dashboard
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: "MyNavbar",
  data: () => ({}),
  methods: {},
  watch: {},
  computed: {},
}
</script>

<style lang="scss" scoped>
ul {
  li {
    a {
      color: white !important;
      text-shadow: -1px -1px 0 #195867, 1px -1px 0 #195867, -1px 1px 0 #195867, 1px 1px 0 #195867;
    }

  }
}
</style>