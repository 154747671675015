import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import {createPinia} from "pinia";
import Vue3Lottie from 'vue3-lottie'
import ElementPlus from 'element-plus'

import 'element-plus/dist/index.css'
import './index.css'

const pinia = createPinia()

const app = createApp(App)

app.use(ElementPlus).use(Vue3Lottie).use(pinia).use(router).mount('#app')
