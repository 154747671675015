<template>
  <div class="flex flex-col">
    <header class="relative xl:h-[100vh] h-[32vh] sm:h-[40vh] md:h-[50vh] lg:h-[74vh]">
      <Navbar/>
      <img src="/img/png/header-bg.png" class="h-full w-full absolute top-0 z-[-1]" alt="header">
      <div class="absolute text-left left-[52vw] top-[17vw] pr-[3.5vw]">
        <h1 class="text-[3.3vw] text-dark font-black">
          A Complete Wellbeing <br>
          Program For Schools</h1>
        <h2 class="text-[1.5vw]">A comprehensive platform to monitor and enrich student and teacher wellbeing.</h2>

        <div class="buttons mt-[2.4vw] text-white w-full gap-2 flex justify-end pr-[3.5vw]">
          <button @click="$router.push('/sign-up')" class="px-4 py-2 bg-green-500 text-[1.3vw] rounded-[2vw] uppercase">
            Sign up
          </button>
        </div>
      </div>
    </header>
    <main class="bg-[#F8F7FC] pt-10">
      <section class="container">
        <div class="flex items-center gap-10">
          <img class="max-w-[50%]" src="/img/home/1.png">

          <ul class="text-[1.5vw] font-regular flex flex-col gap-2 items-start text-left">
            <li>
              - There's been a <span class="text-dark">162%</span> increase in serious mental health problems in
              teenagers since
              2019.
            </li>
            <li>
              - <span class="text-dark">30%</span> of teachers are leaving the profession within five years.
            </li>
            <li>
              - Teachers are submitting more mental health Workcover claims than any other profession.
            </li>
            <li>
              - The great majority of teachers have not been formally trained in student wellbeing and mental health.
            </li>
          </ul>
        </div>
      </section>
      <section class="container flex flex-col items-center text-[1.5vw] py-16">
        <p>
          Wellbeing programs in schools are often a mixture of different products that frequently fail to cover all the
          teacher wellbeing and student wellbeing needs of a school.
        </p>
        <img class="max-w-[60%]" src="/img/home/2.png">
        <p>It is not uncommon for a school to be using several teacher wellbeing and student wellbeing platforms, which,
          collectively, can be very expensive and logistically inefficient.
        </p>
      </section>
      <section class="bg-dark mx-4">
        <div class="w-full p-5 text-white py-[5vw]">
          <p class="text-[2.2vw] font-black">Truwell is a game-changer.
          </p>
          <p class="text-[1.5vw] w-[90%] mt-3">We offer a comprehensive solution that not only monitors teacher
            wellbeing and student wellbeing, it also
            gives staff and students a voice in recommending wellbeing improvement.

          </p>
        </div>
      </section>
      <section class="mx-4 py-16 relative">
        <div class="flex">
          <img class="max-w-[60%]" src="/img/home/3.png">
          <div class="text max-w-[40%]">
            <ul class="flex flex-col gap-4">
              <li class="flex text-[1.5vw] items-center text-left gap-3">
                <img class="object-cover min-w-[50px]" src="/img/home/check.png">
                <span>
                  At Truwell, we prefer to build a fence at the top of the wellbeing cliff rather than just send ambulances to the bottom.
                </span>
              </li>
              <li class="flex text-[1.5vw] items-center text-left gap-3">
                <img class="object-cover min-w-[50px]" src="/img/home/check.png">
                <span>
Truwell provides over 60 pro-active courses.
                </span>
              </li>
              <li class="flex text-[1.5vw] items-center text-left gap-3">
                <img class="object-cover min-w-[50px]" src="/img/home/check.png">
                <span>
These Truwell courses are engaging, preventative and redemptive.
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="absolute top-[60%] w-full">
          <h3 class="text-dark w-[70%] mx-auto text-center text-[2.2vw] font-black">
            THERE ARE CURRENTLY THREE MAIN ELEMENTS TO THE TRUWELL PROGRAM:
          </h3>
          <div class="grid grid-cols-2 gap-6 mt-[3vw] text-[1.5vw] uppercase font-black text-black">
            <div class="flex w-full items-center justify-center">
              <div class="box flex flex-col max-w-[60%]">
                <img src="/img/home/5.png">
                <p>
                  A STUDENT WELLBEING PROGRAM
                </p>
              </div>
            </div>
            <div class="flex w-full items-center justify-center">
              <div class="box flex flex-col max-w-[60%]">
                <img src="/img/home/7.png">
                <p>
                  A TEACHER TRAINING PROGRAM IN STUDENT MENTAL HEALTH
                </p>
              </div>
            </div>
            <div class="flex w-full items-center justify-center">
              <div class="box flex flex-col max-w-[60%]">
                <img src="/img/home/4.png">
                <p>
                  A TEACHER & SUPPORT STAFF WELLBEING PROGRAM
                </p>
              </div>
            </div>
            <div class="flex w-full items-center justify-center">
              <div class="box flex flex-col max-w-[60%]">
                <img src="/img/home/6.png">
                <p>
                  A PRIMARY STUDENT WELLBEING PROGRAM


                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="container py-16 mt-[24%]">
        <div class="flex">
          <div class="flex flex-col max-w-[60%] font-medium text-left text-[1.5vw]">
            <h3 class="font-black text-dark text-[2.5vw] w-full text-center">
              TRUWELL CARE
            </h3>
            <p>
              A wellbeing solution uniquely created for your school
            </p>
            <div class="h-[3%] w-[40%] my-2 bg-dark"></div>
            <p>
              Truwell is built upon decades of experience and research to assist schools with implementing the best
              practices to school-wide wellbeing.

            </p>
          </div>

          <img class="max-w-[55%]" src="/img/home/8.png">
        </div>
      </section>
      <section class="mx-4 bg-dark p-5 my-16">
        <h3 class="text-[1.75rem] text-white font-black">
          A COMPLETE SCHOOL WELLBEING PROGRAM
        </h3>
      </section>
      <section class="container">
        <div class="flex flex-col gap-16 text-left">
          <div class="flex gap-6 items-center justify-between even:flex-row-reverse" v-for="element in features"
               :key="element.title">
            <div class="flex flex-col gap-2 max-w-[40%]">
              <h4 class="text-dark font-black text-[2.5vw]">
                {{ element.title }}
              </h4>
              <p class="font-medium text-[1.5vw]">
                {{ element.description }}
              </p>
            </div>

            <img class="max-w-[50%]" :src="`/img/home/${element.img}`">
          </div>
        </div>
      </section>
      <section class="container flex flex-col my-16">
        <h4 class="text-dark my-4 w-full text-center text-[1.75rem]">
          ARTICLES
        </h4>
        <div class="grid grid-cols-2 gap-8">
          <div class="flex gap-4 rounded p-2 hover:text-blue-700 text-left shadow bg-[#EDF1F5]">
            <img class="w-[33%]" src="/img/home/vapes.png">
            <p class="w-[67%] text-[1.5vw]">From bootcamps in China to Australian schools: How vapes hook children on nicotine</p>
          </div>
          <div class="flex gap-4 rounded p-2 hover:text-blue-700 text-left shadow bg-[#EDF1F5]">
            <img class="w-[33%]" src="/img/home/girl.png">
            <p class="w-[67%] text-[1.5vw]">Sexual consent lessons in schools: Common phrases that could land Australian teenagers in jail</p>
          </div>
          <div class="flex gap-4 rounded p-2 hover:text-blue-700 text-left shadow bg-[#EDF1F5]">
            <img class="w-[33%]" src="/img/home/mental.png">
            <p class="w-[67%] text-[1.5vw]">The new platform helping teachers with mental wellbeing</p>
          </div>
          <div class="flex gap-4 rounded p-2 hover:text-blue-700 text-left shadow bg-[#EDF1F5]">
            <img class="w-[33%]" src="/img/home/consent.png">
            <p class="w-[67%] text-[1.5vw]">Talking to kids about consent: Tough conversations parents need to have with their sons</p>
          </div>
        </div>
      </section>
      <section class="container flex flex-col my-16">
        <h4 class="text-dark my-4 w-full text-center text-[1.75rem]">
Our partners
        </h4>

        <div class="grid grid-cols-3 gap-[3vw]">
          <img :key="idx" v-for="(_,idx) in Array.from({length: 9})" :src="`/img/home/logos/${idx + 1}.png`">
        </div>
      </section>
    </main>
    <MyFooter/>
  </div>
</template>

<script setup>
import Navbar from "@/components/Shared/Navbar.vue";
import MyFooter from "@/components/Shared/MyFooter.vue";

const features = [
  {
    title: "A simple dashboard for busy school leaders",
    description: "Review teacher wellbeing and student wellbeing trends and process suggestions to improve school wellbeing.",
    img: "woman.png"
  },
  {
    title: "Empower staff wellbeing",
    description: "Over 20 interactive self-paced wellbeing courses for your teachers and support staff.",
    img: "hands.png"
  },
  {
    title: "Enrich student wellbeing",
    description: "Enjoy contemporary and interactive digital courses that are engaging and relevant.",
    img: "laptop.png"
  },
  {
    title: "All the surveys your school needs in one location",
    description: "Track teacher wellbeing and student with our powerful surveys and wellbeing check-ins. Customise the surveys and send an individualised letter to your staff and students at the end of each survey.  ",
    img: "phone.png"
  },
  {
    title: "Take actionable steps to improve teacher wellbeing and student wellbeing",
    description: "Truwell assists schools to improve wellbeing by suggesting practical steps to enrich the wellbeing of staff and students.",
    img: "man.png"
  },
  {
    title: "Designed for your school",
    description: "School leaders are empowered to monitor the wellbeing trends of their entire school community.\n" +
        "\n" +
        "They are also enabled to customise the student wellbeing program and “badge” it as their own.",
    img: "class.png"
  }
]
</script>


<style scoped lang="scss">
</style>