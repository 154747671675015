import {Api} from "@/utils/api";

export class StudentApi extends Api {
    constructor() {
        super();
    }

    async getStudents() {
        return await this.getStorage("/student")
    }

    async getByEmail(email, sub) {
        try {
            return await this.getStorage("/student/email", {email, sub})
        } catch (e) {
            return false
        }
    }

    async createUser(data) {
        return await this.postStorage("/student", data)
    }

    async updateUser(id, data) {
        return await this.patchStorage("/student/" + id, data)
    }

    async updateMany(data, deletedIds) {
        return await this.patchStorage("/student/many", {
            data: data, deletedIds
        })
    }

    async deleteUser(id) {
        return await this.deleteStorage("/student/" + id)
    }
}
