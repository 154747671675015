<template>
  <router-view/>
</template>

<script>
import {Api} from "@/utils/api";
import {SchoolApi} from "@/utils/schoolApi";
import {StudentApi} from "@/utils/studentApi";

export default  {
  data: () => ({
    api: new Api(),
    schoolApi: new SchoolApi(),
    studentApi: new StudentApi(),
  })
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
