import { createRouter, createWebHistory } from 'vue-router'
import HomeView from "@/views/HomeView.vue";
import AnalyticsView from "@/views/AnalyticsView.vue";
import AdminView from "@/views/AdminView.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/student-wellbeing',
    name: "student-wellbeing",
    component: () => import("@/views/analytics/StudentWellbeing.vue")
  },
  {
    path: "/studentwellbeingcheck-in",
    name: "student-wellbeing-landing-page",
    component: () => import("@/views/StudentWellbeingCheckinView.vue")
  },
  {
    path: "/studentwellbeing",
    name: "student-wellbeing2",
    component: () => import("@/views/StudentWellbeing.vue")
  },
  {
    path: '/analytics/:id',
    name: 'analytics',
    component: AnalyticsView
  },{
    path: '/admin',
    name: 'admin',
    component: AdminView
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: () => import("@/views/SignUpView.vue")
  },
  {
    path: "/students-panel",
    name: "students-panel",
    component: () => import("@/views/StudentsPanelView.vue")
  },
  {
    path: '/survey/:id',
    name: "survey page",
    component: () => import("@/views/SurveyView.vue")
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
