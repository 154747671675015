<template>
  <div class="text-left flex flex-col gap-2">
    <div class="tabs w-full flex gap-2">
      <div @click="selectedQuestion = q" :class="selectedQuestion === q ? 'border-primary border' : '' " class="text-primary p-2 rounded" v-for="q in questions" :key="q">
        {{q}}
      </div>
    </div>

    <div v-if="answers?.length > 0" class="flex flex-col gap-2">
      <div :class="pinAnswers[pin]?.includes(a.id) ? 'bg-blue-100' : null" class="shadow-md shadow-primary-light text-primary p-4" v-for="a in answers.sort(sortCallback)" :key="a.id">
        {{a.text}}

        <button @click="pinComment(a.id)">
          <i :class="`${pinAnswers[pin]?.includes(a.id) ? 'fa-xmark' : 'fa-map-pin'}`" class="fa-solid"></i>
        </button>
      </div>
    </div>
    <span class="text-center font-semibold" v-else>No answers</span>
  </div>
</template>

<script>
import {storage} from "@/utils";

export default {
  name: "CommentAnalytic",
  props: {
    pin: {
      type: String
    },
    data: {
      type: Array
    }
  },
  data: () => ({
    selectedQuestion: "",
    pinAnswers: {}
  }),
  mounted() {
    this.selectedQuestion = this.questions[0]
    const obj = storage.pinned_answers;

    if (obj) {
      this.pinAnswers = obj;
    }
  },
  methods: {
    sortCallback(a,b) {
      const arr = this.pinAnswers[this.pin]
      const aIncluded = arr?.includes(a.id);
      const bIncluded = arr?.includes(b.id);

      if (aIncluded && !bIncluded) {
        return -1;
      } else if (!aIncluded && bIncluded) {
        return 1;
      } else {
        return a - b;
      }
    },
    pinComment(id) {
      let pinAnswers = {...storage.pinned_answers};

      if (!storage.pinned_answers) {
        storage.pinned_answers = {[this.pin]: [id]}
      } else {
        if(!pinAnswers[this.pin]) {
          storage.pinned_answers[this.pin] = [id]
        } else {
          if(storage.pinned_answers[this.pin]?.includes(id)) {
            pinAnswers[this.pin] = pinAnswers[this.pin].filter((v) => v !== id)
            storage.pinned_answers = pinAnswers
          } else {
            pinAnswers[this.pin].push(id)
            storage.pinned_answers = pinAnswers
          }
        }
      }

      this.pinAnswers = pinAnswers
    }
  },
  computed: {
    comments() {
      let q = _.groupBy(this.data, 'question')

      // let res = []
      //
      // Object.keys(q).forEach((k) => {
      //   let arr = q[k].map((e) => e.text)
      //
      //   res.push({question: k, answers: arr})
      // })

      return q
    },
    questions() {
      return Object.keys(this.comments)
    },
    answers() {
      return this.comments[this.selectedQuestion]?.filter(e => !!e.text)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>