<template>
  <div class="text-left">
    <div>
      <div class="flex flex-col gap-2" v-if="!active">
        <button
            class="w-full flex justify-between"
            v-for="(d, idx) in firstData"
            :key="idx"
        >
          <div>
            {{ d.name }}:
          </div>
          <div class="flex gap-2">
            <div class="px-4 rounded-xl w-full text-white max-w-fit"
                 :style="{background: mapper[Math.round(d.average) - 1]}">
              {{ d.average }}
            </div>

            <div class="flex rounded-lg overflow-hidden max-w-fit">
              <div :style="{background: mapper[i], width: 420 * p / 100 + 'px'}" class="text-center text-white"
                   v-for="(p, i) in d.percentage" :key="i">
                {{ p }}%
              </div>
            </div>

          </div>


        </button>
      </div>

      <!--      <button @click="active = false" v-else class="flex rounded-lg overflow-hidden max-w-fit">-->
      <!--        <div :style="{background: mapper[i], width: 420 * p / 100 + 'px'}" class="text-center text-white"-->
      <!--             v-for="(p, i) in distribution" :key="i">-->
      <!--          {{ p }}%-->
      <!--        </div>-->
      <!--      </button>-->

    </div>
  </div>
</template>

<script>

import _ from "lodash";

export default {
  name: "LongTerm",
  components: {},
  props: {
    i: {
      type: Number
    },
    q: {
      type: String
    },
    data: {
      type: Object,
    }
  },
  data: () => ({
    active: false,
    mapper: [
      '#d21f3d',
      '#f9812a',

      '#ffe22c',

      '#06b25f',

      '#0078d7']
  }),
  computed: {
    firstData() {
      let q = _.groupBy(this.data[this.i], 'question')

      const arr = []

      Object.keys(q).forEach((k) => {
        let e = q[k]
        let obj = {
          questions: e,
          name: k,
          percentage: ""
        }
        let sum = e.reduce((acc, next) => acc += next.rating, 0)
        let total = (sum / e.length).toFixed(2)

        obj["average"] = total

        let map = {}
        let percentage = {}
        let count = 0;

        Array.from({length: 5}).forEach((_, i) => {
          map[i + 1] = 0
        })

        // map[4] += 2
        // count += 2

        e.forEach((v) => {
          count += 1
          map[v.rating] += 1
        })

        const values = Object.entries(map)

        values.forEach((v, i) => {
          percentage[v[0]] = +((v[1] / count * 100).toFixed(1))
        })

        obj["percentage"] = Object.values(percentage)

        arr.push(obj)
      })

      return arr
    },
    distribution() {
      let map = {}
      let percentage = {}
      let count = 0;

      Array.from({length: 5}).forEach((_, i) => {
        map[i + 1] = 0
      })

      // map[4] += 2
      // count += 2

      this.data[this.i].forEach((v) => {
        count += 1
        map[v.rating] += 1
      })

      const values = Object.entries(map)

      values.forEach((v, i) => {
        percentage[v[0]] = +((v[1] / count * 100).toFixed(1))
      })

      return Object.values(percentage)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>